import { format } from 'date-fns'
import goTo from "vuetify/lib/services/goto";

export default {
    updateProgress(action) {
        const self = this

        self.$store.state.errors = {}
        if (!self.ignoreValidations) {
            if (self.errors?.length) {
                self.errors.forEach((err) => {
                    self.$store.state.errors[err.path] = err.message
                })

                goTo(self.errors[0].hash)

                return
            }

            if (!self.canContinue) {
                return
            }
        }

        if (typeof action === 'function') {
            return action()
        }

        self.$store.dispatch('updateCart', self.cartData)
            .then(() => {
                switch (typeof action) {
                    case 'object':
                        if (action.name === 'Login' && window.dataLayer) {
                            const dataLayerEvent = {
                                event: 'add_to_cart',
                                currency: 'BRL',
                                value: self.totalValue,
                                items: [
                                    {
                                        item_id: self.trip.id,
                                        item_name: self.trip.eventTitle,
                                        affiliation: self.trip.agency.name
                                    }
                                ]
                            }

                            window.dataLayer.push(dataLayerEvent)
                        }
                        this.$router.push(action)
                        break;
                    case 'function':
                        action()
                        break;
                    case 'string':
                    default:
                        location.href = action
                        break;
                }
            })
            .catch(error => {
                console.log(error)
            })
    },

    getCartItems() {
        const result = []

        let boardingPlace = this.$store.state.trip.boardingPlaces.filter(place => {
            return place.id === this.$store.state.cart.boardingPlace
        })[0]

        if (boardingPlace) {
            const time = format(new Date(boardingPlace.date), 'dd/MM/yyyy HH:mm')
            boardingPlace = `Embarque: ${boardingPlace.place} - ${time}`
        }

        this.$store.state.cart.accommodations.forEach(accommodation => {
            result.push({
                item_id: this.$store.state.cart.id,
                item_name: `${this.$store.state.trip.eventTitle} - ${this.$store.state.trip.agency.name}`,
                affiliation: this.$store.state.trip.agency.name,
                price: accommodation.value,
                quantity: accommodation.quantity,
                item_variant: boardingPlace
            })
        })

        this.$store.state.cart.optionals.forEach(optional => {
            result.push({
                item_id: this.$store.state.cart.id,
                item_name: `${this.$store.state.trip.eventTitle} - ${this.$store.state.trip.agency.name}`,
                affiliation: this.$store.state.trip.agency.name,
                price: optional.value,
                quantity: optional.quantity
            })
        })

        return result
    }
}
