import computed from './attrs/computed'
import data from './attrs/data'
import methods from './attrs/methods'
import props from './attrs/props'

export default {
    name: 'CartOverview',
    computed,
    data,
    methods,
    props
}
