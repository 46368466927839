export default {
    nextStep: Object,
    complete: {
        type: Boolean,
        default: false
    },
    nextStepText: {
        type: String,
        default: 'Continuar reserva'
    },
    action: Function,
    canContinue: {
        type: Boolean,
        default: false
    },
    errors: [],
    ignoreValidations: {
        type: Boolean,
        default: false
    }
}
