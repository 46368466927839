import { mapState } from 'vuex'

export default mapState({
    trip: state => state.trip,
    durationDays: (state) => {
        const duration = state.trip.duration + 1

        return duration > 1 ? `${duration} dias` : 'Bate-volta'
    },
    cart: state => state.cart,
    totalValue: function(state) {
        return (
            state.cart.optionals.map(optional => optional.price * optional.quantity).reduce((partial, a) => partial + a, 0) +
            state.cart.accommodations.map(accommodation => accommodation.price * accommodation.quantity).reduce((partial, a) => partial + a, 0)
        )
    },
    paymentMethod: state => state.paymentMethod,
    installments: state => state.installments,
    cartData: function(state) {
        let accommodations = state.cart.accommodations
            accommodations.forEach(accommodation => {
                accommodation.optionTypeId = accommodation.id
            })

        return {
            accommodations,
            optionals: state.cart.optionals,
            boardingPlace: `/trip_boardings/${state.cart.boardingPlace}`,
            user: state?.session?.user?.id,
            paymentType: state.paymentMethod === 'pix' ? 3 : 1,
            travelers: state.cart.travelers,
            installments: state?.installments?.value ?? 1
        }
    }
})
